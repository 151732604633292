import { useMsal } from '@azure/msal-react'
import { Chip, CircularProgress, IconButton, AppBar } from '@mui/material'
import { AddOutlined, DeleteOutlined, RefreshOutlined } from '@mui/icons-material'
import {
  acquireAccessToken,
  CenteredDiv,
  CustomDataGrid,
  ErrorMessage,
  getConfiguration,
  MenuAppBar,
  PrimaryButton,
  RegularButton,
  SnackbarVariants
} from '@wavetronix/common-components'
import axios from 'axios'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { env } from '../index.js'
import ApiModal from './ApiModal'
import SectionRuleModal from './SectionRuleModal'
import DeleteRuleModal from './DeleteRuleModal'
import { useEffect } from 'react'
import version from '../env/version.json'

export default function MainPage() {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [syncing, setSyncing] = useState(false)
  const [apiModalOpen, setApiModalOpen] = useState(false)
  const [ruleModalOpen, setRuleModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [ruleToDelete, setRuleToDelete] = useState({})
  const [currentRule, setCurrentRule] = useState({})
  const [variant, setVariant] = useState('create')
  const [pageSize, setPageSize] = useState(100)
  const [ruleModalState, setRuleModalState] = useState({
    open: false,
    rule: {}
  })

  const columns = [
    {
      field: 'name',
      headerName: 'Section Name',
      flex: 1,
      disableColumnMenu: true,
      sortable: true
    },
    {
      field: 'applyToCircles',
      headerName: 'Applied to Circles',
      flex: 1,
      disableColumnMenu: true,
      sortable: true,
      renderCell: item => <Chip label={item.row.applyTo.includes('Circles') ? 'Yes' : 'No'} variant='outlined' />
    },
    {
      field: 'applyToRoles',
      headerName: 'Applied to Roles',
      flex: 1,
      disableColumnMenu: true,
      sortable: true,
      renderCell: item => <Chip label={item.row.applyTo.includes('Roles') ? 'Yes' : 'No'} variant='outlined' />
    },
    {
      field: 'tags',
      headerName: 'Applied Tags',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: item => item.value.map(tag => <Chip key={tag} label={tag} variant='outlined' color='primary' />)
    },
    {
      field: 'delete',
      headerName: 'Delete',
      disableColumnMenu: true,
      sortable: false,
      renderCell: item => (
        <IconButton
          onClick={() => {
            setRuleToDelete(item.row)
            setDeleteModalOpen(true)
          }}
          color='primary'
          size='large'>
          <DeleteOutlined />
        </IconButton>
      )
    }
  ]

  useEffect(() => {
    setCurrentRule(ruleModalState.rule)
    setVariant('edit')
    setRuleModalOpen(ruleModalState.open)
  }, [ruleModalState])

  async function getSectionRules() {
    let token = await acquireAccessToken(instance, accounts, env)

    let config = getConfiguration(token)

    return await axios
      .get(`${env.urls.glassfrogURL}/api/v1/rules`, {
        ...config,
        timeout: 45000
      })
      .then(res => res.data)
  }

  async function processRules() {
    setSyncing(true)
    let key = enqueueSnackbar('Syncing...', SnackbarVariants.LOADING)
    let token = await acquireAccessToken(instance, accounts, env)

    let config = getConfiguration(token)

    await axios
      .post(
        `${env.urls.glassfrogURL}/api/v1/rules/process`,
        {},
        {
          ...config,
          timeout: 45000
        }
      )
      .then(() => {
        closeSnackbar(key)
        enqueueSnackbar('Section rules processed/synced successfully!', SnackbarVariants.SUCCESS)
      })
      .catch(e => {
        closeSnackbar(key)
        enqueueSnackbar('An error occurred while syncing section rules.', SnackbarVariants.ERROR)
      })
      .finally(() => setSyncing(false))
  }

  const { data, isLoading, refetch, error, isFetching, isRefetching } = useQuery({
    queryKey: ['rules'],
    queryFn: async () => await getSectionRules(instance, accounts)
  })

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <>
      <div style={{ margin: '0px 25px 0px 25px' }}>
        <MenuAppBar env={env} appHeader='Glassfrog Tool' />
        <br />
        <RegularButton disabled={isFetching} onClick={refetch}>
          {isRefetching ? <CircularProgress size={24} /> : <RefreshOutlined />}
          Refresh Rules
        </RegularButton>
        <div style={{ float: 'right' }}>
          <RegularButton onClick={processRules} startIcon={<RefreshOutlined />} disabled={syncing}>
            Sync Table
          </RegularButton>
          &emsp;
          <RegularButton onClick={() => setApiModalOpen(true)}>Edit Glassfrog API Key</RegularButton>
        </div>
        <br />
        <br />
        <CustomDataGrid
          rows={data ? data : []}
          autoHeight
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={p => setPageSize(p.pageSize)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          disableSelectionOnClick
          onRowClick={e => setRuleModalState({ open: true, rule: e.row })}
          loading={isLoading}
          cursor='pointer'
        />
        <br />
        <PrimaryButton onClick={() => setRuleModalOpen(true)} startIcon={<AddOutlined />} style={{ float: 'right' }}>
          New Section Rule
        </PrimaryButton>
        <ApiModal open={apiModalOpen} onClose={() => setApiModalOpen(false)} />
        <SectionRuleModal
          open={ruleModalOpen}
          onClose={() => {
            setRuleModalOpen(false)
            setCurrentRule({})
            setVariant('create')
          }}
          refetch={refetch}
          rule={currentRule}
          variant={variant}
        />
        <DeleteRuleModal
          open={deleteModalOpen}
          onClose={() => {
            setDeleteModalOpen(false)
            setRuleToDelete({})
          }}
          rule={ruleToDelete}
          refetch={refetch}
        />
      </div>
      <AppBar position='fixed' sx={{ top: 'auto', bottom: 0, backgroundColor: 'transparent', color: 'black', boxShadow: 'none' }}>
        <div style={{ float: 'right' }}>{version.version}</div>
      </AppBar>
    </>
  )
}
