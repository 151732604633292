import { useMsal } from '@azure/msal-react'
import { CircularProgress, Divider, IconButton, Dialog, DialogContent, DialogActions, TextField } from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import {
  acquireAccessToken,
  CenteredDiv,
  getConfiguration,
  PrimaryButton,
  RegularButton,
  SnackbarVariants
} from '@wavetronix/common-components'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { env } from '../index.js'

export default function ApiModal({ open, onClose }) {
  const { instance, accounts } = useMsal()

  async function getApiKey() {
    let token = await acquireAccessToken(instance, accounts, env)
    let config = getConfiguration(token)
    return await axios
      .get(`${env.urls.glassfrogURL}/api/v1/apikey`, {
        ...config,
        timeout: 45000
      })
      .then(res => res.data)
  }

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { data, isLoading, refetch } = useQuery({ queryKey: ['apiKey'], queryFn: getApiKey })
  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  async function saveApiKey() {
    setButtonsDisabled(true)
    let snackbarKey = enqueueSnackbar('Updating API key...', SnackbarVariants.LOADING)
    let token = await acquireAccessToken(instance, accounts, env)
    let config = getConfiguration(token)
    await axios
      .post(`${env.urls.glassfrogURL}/api/v1/apikey?key=${apiKey}`, {}, { ...config, timeout: 45000 })
      .then(async () => {
        await refetch()
        closeSnackbar(snackbarKey)
        enqueueSnackbar('Successfully updated API key.', SnackbarVariants.SUCCESS)
      })
      .catch(e => {
        closeSnackbar(snackbarKey)
        enqueueSnackbar('An error occurred while updating API key.', SnackbarVariants.ERROR)
      })
      .finally(() => setButtonsDisabled(false))
  }

  const handleKeyDown = e => (e.key === 'Enter' ? saveApiKey() : null)

  function onCancel() {
    if (!buttonsDisabled) {
      onClose()
      setApiKey(data)
    }
  }

  const [apiKey, setApiKey] = useState()
  useEffect(() => setApiKey(data), [data])

  return (
    <Dialog open={open} onClose={onCancel} maxWidth={'sm'} fullWidth={true}>
      <DialogContent>
        <div>
          <div style={{ height: '50px' }}>
            <IconButton onClick={onCancel} style={{ float: 'right' }} disabled={buttonsDisabled} size='large'>
              <CloseOutlined />
            </IconButton>
            <br />
            <h3 style={{ display: 'inline' }}>Glassfrog API Key</h3>
          </div>
          <Divider />
          <br />
          {isLoading ? (
            <CenteredDiv>
              <CircularProgress />
            </CenteredDiv>
          ) : (
            <TextField
              label='API Key'
              style={{ width: '100%' }}
              onChange={e => setApiKey(e.target.value)}
              value={apiKey}
              variant='outlined'
              onKeyDown={handleKeyDown}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <PrimaryButton disabled={data === apiKey || buttonsDisabled} onClick={saveApiKey}>
          Save
        </PrimaryButton>
        <RegularButton onClick={onCancel} disabled={buttonsDisabled}>
          Cancel
        </RegularButton>
      </DialogActions>
    </Dialog>
  )
}
