import { useMsal } from '@azure/msal-react'
import {
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  Dialog,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import {
  acquireAccessToken,
  CenteredDiv,
  CustomCheckbox,
  getConfiguration,
  PrimaryButton,
  RegularButton,
  SnackbarVariants
} from '@wavetronix/common-components'
import axios from 'axios'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useQuery } from '@tanstack/react-query'
import { env } from '../index.js'

const editorOptions = ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history']

export default function SectionRuleModal({ open, onClose, refetch, rule, variant }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [purpose, setPurpose] = useState('')
  const [sectionName, setSectionName] = useState('')
  const [applyTo, setApplyTo] = useState([])
  const [tagsToApply, setTagsToApply] = useState({})
  const [widgetsDisabled, setWidgetsDisabled] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [hasMadeChange, setHasMadeChange] = useState(false)

  useEffect(() => {
    if (variant === 'edit') {
      setHasMadeChange(false)
    }
  }, [variant])

  useEffect(() => {
    if (rule.purpose) {
      setPurpose(rule.purpose)
    }
    if (rule.name) {
      setSectionName(rule.name)
    }
    if (rule.applyTo) {
      setApplyTo([...rule.applyTo])
    }
    if (rule.tags) {
      rule.tags.forEach(tag => setTagsToApply(tags => ({ ...tags, [tag]: true })))
    }
    if (rule.purpose && rule.purpose.length > 0) {
      setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(rule.purpose).contentBlocks)))
    }
  }, [rule])

  function onApplyToCheckChange(e, s) {
    setApplyTo(a => {
      if (e.target.checked && !a.includes(s)) {
        a.push(s)
      } else if (!e.target.checked) {
        a = a.filter(x => x !== s)
      }
      return [...a]
    })
    setHasMadeChange(true)
  }

  function onPurposeChange(newEditorState) {
    setPurpose(draftToHtml(convertToRaw(newEditorState.getCurrentContent())))
    setEditorState(newEditorState)
    setHasMadeChange(true)
  }

  function closeModal() {
    onClose()
    setEditorState(EditorState.createEmpty())
    setPurpose('')
    setSectionName('')
    setApplyTo([])
    if (data) {
      setTagsToApply(
        data.reduce((map, tag) => {
          map[tag] = false
          return map
        }, {})
      )
    } else {
      setTagsToApply({})
    }
  }

  function onCancel() {
    if (!widgetsDisabled) {
      closeModal()
    }
  }

  const { instance, accounts } = useMsal()

  function saveRule(newRule, config) {
    if (variant === 'edit') {
      return axios.put(`${env.urls.glassfrogURL}/api/v1/rules/${newRule.id}`, newRule, config)
    } else {
      return axios.post(`${env.urls.glassfrogURL}/api/v1/rules`, newRule, config)
    }
  }

  async function onPrimaryClick() {
    setWidgetsDisabled(true)
    let key = enqueueSnackbar(`${variant === 'edit' ? 'Edit' : 'Creat'}ing section rule...`, SnackbarVariants.LOADING)

    let token = await acquireAccessToken(instance, accounts, env)

    let newRule = {
      id: rule.id ? rule.id : '',
      applyTo: applyTo,
      name: sectionName,
      purpose: purpose,
      operation: 'Add',
      isAddRuleOperation: true,
      tags: Object.keys(tagsToApply).filter(k => tagsToApply[k])
    }
    let config = { ...getConfiguration(token), timeout: 45000 }

    await saveRule(newRule, config)
      .then(async () => {
        await refetch()
        closeSnackbar(key)
        enqueueSnackbar(`Successfully ${variant === 'edit' ? 'edi' : 'crea'}ted section rule`, SnackbarVariants.SUCCESS)
        closeModal()
      })
      .catch(e => {
        closeSnackbar(key)
        enqueueSnackbar('An error occurred', SnackbarVariants.ERROR)
      })
      .finally(() => setWidgetsDisabled(false))
  }

  async function getAllTags() {
    let token = await acquireAccessToken(instance, accounts, env)

    const refresh = false
    let config = getConfiguration(token)

    return await axios
      .get(`${env.urls.glassfrogURL}/api/v1/glassfrog/tags/all?refresh=${refresh}`, { ...config, timeout: 45000 })
      .then(res => res.data)
  }

  const { data, isLoading } = useQuery({ queryKey: ['sectionRuleTags'], queryFn: getAllTags })

  useEffect(() => {
    if (data) {
      setTagsToApply(
        data.reduce((map, tag) => {
          map[tag] = false
          return map
        }, {})
      )
    }
  }, [data])

  return (
    <Dialog open={open} onClose={onCancel} maxWidth={'xl'} fullWidth={true}>
      <DialogContent>
        <div>
          <div style={{ height: '10%' }}>
            <IconButton onClick={onCancel} style={{ float: 'right' }} disabled={widgetsDisabled} size='large'>
              <CloseOutlined />
            </IconButton>
            <br />
            <h3 style={{ display: 'inline' }}>Add New Section Rule</h3>
          </div>
          <Divider />
          <div style={{ overflow: 'auto', height: '90%' }}>
            <br />
            <TextField
              variant='standard'
              label='Section Name'
              style={{ width: '250px' }}
              onChange={e => {
                if (e.target.value.length <= 32) {
                  setSectionName(e.target.value)
                }
              }}
              value={sectionName}
              required
              disabled={widgetsDisabled || variant === 'edit'}
            />
            <br /> <br />
            <h4>Section Purpose</h4>
            <Editor
              toolbarClassName='toolbar-class'
              toolbar={{
                options: editorOptions,
                inline: { inDropdown: true },
                fontSize: { inDropdown: true },
                fontFamily: { inDropdown: true },
                textAlign: { inDropdown: true },
                list: { inDropdown: true }
                // commented out just in case for future implementation.
                // link: { inDropdown: true },
              }}
              editorState={editorState}
              onEditorStateChange={onPurposeChange}
              disabled={widgetsDisabled}
            />
            <br />
            <h4>Areas to Apply:</h4>
            <CustomCheckbox
              checked={applyTo.includes('Roles')}
              onChange={e => onApplyToCheckChange(e, 'Roles')}
              disabled={widgetsDisabled}
            />
            &nbsp; Apply to role(s) &emsp;
            <CustomCheckbox
              checked={applyTo.includes('Circles')}
              onChange={e => onApplyToCheckChange(e, 'Circles')}
              disabled={widgetsDisabled}
            />
            &nbsp; Apply to circle(s)
            <br />
            <h4>Tag(s) to apply</h4>
            <List>
              {isLoading ? (
                <CenteredDiv>
                  <CircularProgress />
                </CenteredDiv>
              ) : (
                data.map(tag => (
                  <ListItem key={tag}>
                    <CustomCheckbox
                      checked={tagsToApply[tag]}
                      onChange={e => {
                        setTagsToApply(tags => ({
                          ...tags,
                          [tag]: e.target.checked
                        }))
                        setHasMadeChange(true)
                      }}
                      disabled={widgetsDisabled}
                    />
                    &nbsp; {tag}
                  </ListItem>
                ))
              )}
            </List>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <PrimaryButton
          disabled={
            sectionName.length === 0 ||
            applyTo.length === 0 ||
            Object.keys(tagsToApply).filter(key => tagsToApply[key]).length === 0 ||
            purpose.length === 0 ||
            widgetsDisabled ||
            !hasMadeChange
          }
          onClick={onPrimaryClick}>
          {variant === 'edit' ? 'Save' : 'Create'}
        </PrimaryButton>
        <RegularButton disabled={widgetsDisabled} onClick={onCancel}>
          Cancel
        </RegularButton>
      </DialogActions>
    </Dialog>
  )
}
