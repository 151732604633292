import {
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import {
  acquireAccessToken,
  getConfiguration,
  PrimaryButton,
  RegularButton,
  SnackbarVariants
} from '@wavetronix/common-components'
import React, { useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { env } from '../index.js'
import { useSnackbar } from 'notistack'
import axios from 'axios'

export default function DeleteRuleModal({ open, onClose, rule, refetch }) {
  const [selected, setSelected] = useState('')
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [widgetsDisabled, setWidgetsDisabled] = useState(false)

  function onCancel() {
    if (!widgetsDisabled) {
      onClose()
      setSelected('')
    }
  }

  async function deleteRule() {
    setWidgetsDisabled(true)
    let key = enqueueSnackbar('Deleting...', SnackbarVariants.LOADING)
    let token = await acquireAccessToken(instance, accounts, env)

    let config = getConfiguration(token)
    let ruleOnly = selected === 'ruleOnly'
    await axios
      .delete(`${env.urls.glassfrogURL}/api/v1/rules/${rule.id}?ruleOnly=${ruleOnly}`, { ...config, timeout: 45000 })
      .then(async () => {
        await refetch()
        closeSnackbar(key)
        enqueueSnackbar('Successfully deleted rule.', SnackbarVariants.SUCCESS)
        onClose()
        setSelected('')
      })
      .catch(e => {
        closeSnackbar(key)
        enqueueSnackbar('An error occurred while deleting rule.', SnackbarVariants.ERROR)
      })
      .finally(() => setWidgetsDisabled(false))
  }

  return (
    <Dialog open={open} onClose={onCancel} maxWidth={'sm'} fullWidth={true}>
      <DialogContent>
        <div style={{ height: '50px' }}>
          <IconButton onClick={onCancel} style={{ float: 'right' }} disabled={widgetsDisabled} size='large'>
            <CloseOutlined />
          </IconButton>
          <br />
          <h3 style={{ display: 'inline' }}>Confirm Delete</h3>
        </div>
        <Divider />
        <br />
        <FormControl variant='standard' component='fieldset'>
          <FormLabel component='legend'>Section Rule: {rule ? rule.name : ''}</FormLabel>
          <RadioGroup name='radio-buttons-group' onChange={e => setSelected(e.target.value)} value={selected}>
            <FormControlLabel value='ruleOnly' label='Delete Section Rule' disabled={widgetsDisabled} control={<Radio />} />
            <FormControlLabel
              value='everything'
              label='Delete Section Rule and Everything it Applied.'
              disabled={widgetsDisabled}
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <PrimaryButton disabled={!selected || widgetsDisabled} onClick={deleteRule}>
          Delete
        </PrimaryButton>
        <RegularButton disabled={widgetsDisabled} onClick={onCancel}>
          Cancel
        </RegularButton>
      </DialogActions>
    </Dialog>
  )
}
