import { getEnvInfo } from '@wavetronix/common-components'
import { localEnv } from './localEnv.js'

export default async function generateEnv() {
  var windowURL = window.location.hostname

  if (window.location.hostname.includes('localhost')) {
    windowURL = 'glassfrogtool.wtxdev.com'
  }

  var envInfo = await getEnvInfo(windowURL, localEnv)

  if (window.location.hostname.includes('localhost')) {
    envInfo = { ...localEnv, basicAuthentication: envInfo.basicAuthentication }
  }

  return envInfo
}
